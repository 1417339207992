import config from "../configs/firebase-service.config";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";
import { v4 as uuidv4 } from "uuid";
import Resizer from "react-image-file-resizer";
import Show from "../models/Show";
import Multiplex from "../models/Multiplex";
import { CustomUserPledgeAmounts } from "../contexts/edit-show-pledges.ctx";
import { ShowProject } from "../contexts/edit-show-projects.ctx";
import { CustomRegistration } from "../contexts/edit-show-registrations.ctx";
import { ProjectPledge } from "../components/LiveManager/LivePledgesMonitor/LivePledgesMonitor";
import { ChatMessage } from "../components/LiveManager/LiveChatMonitor/LiveChatMonitor";
import {DashboardProject, MultiplexDashboard} from "../contexts/edit-multiplex-dashboards.ctx";
import {
  DashboardLocalCount
} from "../components/MultiplexManager/MultiplexCurrentProjectsSelector/MultiplexCurrentProjectsSelector";

const firebaseConfig = config();
!firebase.apps.length
  ? firebase.initializeApp(firebaseConfig.sdkCredentials)
  : firebase.app();

const $firestore = firebase.firestore();
const $firebaseStorage = firebase.storage();
const $firebaseAuth = firebase.auth();

if (firebaseConfig.envName === "LOCAL") {
  $firebaseAuth.useEmulator(
    firebaseConfig.emulators.auth.url
  );
  $firestore.useEmulator(
    firebaseConfig.emulators.firestore.host,
    firebaseConfig.emulators.firestore.port
  );
  $firebaseStorage.useEmulator(
    firebaseConfig.emulators.storage.host,
    firebaseConfig.emulators.storage.port
  );
} else {
  //   firebase.analytics();
}



const getHydratedShowFromFetch = (show: Record<string, any>) => {
  return new Show(
    show.id,
    show.data().name,
    show.data().urlSlug,
    show.data().isPublished,
    show.data().isOnPlace,
    show.data().isOnRemote,
    show.data().place,
    show.data().subtitle,
    new Date(show.data().startDate.seconds * 1000),
    new Date(show.data().endDate.seconds * 1000),
    show.data().description,
    show.data().endMessage,
    show.data().endButtonText,
    show.data().endButtonUrl,
    show.data().comingButtonText,
    show.data().comingButtonUrl,
    show.data().pastButtonText,
    show.data().pastButtonUrl,
    show.data().coverUrl,
    show.data().logoUrl,
    show.data().isStreamingEnabled,
    show.data().youtubeStreamId,
    show.data().hasPledgesPresets,
    show.data().firstPledgeAmount,
    show.data().secondPledgeAmount,
    show.data().thirdPledgeAmount,
    show.data().fourthPledgeAmount,
    show.data().pledgesCurrency,
    show.data().hasFreePledgeAmount,
    show.data().hasTaxReceiptAvailable,
    show.data().hasCustomPledgeAmounts,
    show.data().isFirstnamePresetOn,
    show.data().isFirstnamePresetRequired,
    show.data().isLastnamePresetOn,
    show.data().isLastnamePresetRequired,
    show.data().isPhonePresetOn,
    show.data().isPhonePresetRequired,
    show.data().isAddressFirstLinePresetOn,
    show.data().isAddressFirstLinePresetRequired,
    show.data().isAddressSecondLinePresetOn,
    show.data().isAddressSecondLinePresetRequired,
    show.data().isZipcodePresetOn,
    show.data().isZipcodePresetRequired,
    show.data().isCityPresetOn,
    show.data().isCityPresetRequired,
    show.data().isCountryPresetOn,
    show.data().isCountryPresetRequired,
    show.data().customRegistrationsList,
    show.data().currentProjectId,
    show.data().isClosed,
    show.data().totalPledgesAmount,
    show.data().hasNoProjects,
    show.data().customPledgeAmountsCount,
    show.data().averagePledgesAmount,
    show.data().allPledgesCount,
    show.data().totalUsersCount,
    show.data().forcedEndingAmount,
    show.data().globalShowGoal,
    show.data().isLnbc,
    show.data().backgroundColor,
    show.data().fontColor,
    show.data().colorPledge,
    show.data().backgroundColorPledge,
    show.data().backgroundColorPledgeBeyond,
    show.data().isPseudoPresetOn,
    show.data().isPseudoPresetRequired,
    show.data().multiplexId
  );
};

const getHydratedMultiplexFromFetch = (multiplex: any) => {
  return new Multiplex(
    multiplex.id,
    multiplex.data().name,
    multiplex.data().subtitle,
    new Date(multiplex.data().startDate.seconds * 1000),
    multiplex.data().logoUrl,
    multiplex.data().participatingShows,
    multiplex.data().pledgesCurrency,
    multiplex.data().totalPledgesAmount,
    multiplex.data().averagePledgesAmount,
    multiplex.data().allPledgesCount,
    multiplex.data().totalUsersCount,
    multiplex.data().globalMultiplexGoal,
    multiplex.data().currentDashboardId,
    multiplex.data().backgroundColorDark,
    multiplex.data().backgroundColorLight,
    multiplex.data().fontColor,
    multiplex.data().fontColorHighlight,
    multiplex.data().isArchived,
    multiplex.data().displayProgressBar
  );
};

export type ImageAndThumbnailUploadResponse = {
  imagePath: string;
  imageDownloadUrl: string;
  thumbnailPath: string;
  thumbnailDownloadUrl: string;
};

const resizeFile = (fileToResize: File, pngOutput?: boolean) => {
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      fileToResize,
      500,
      500,
      pngOutput ? "PNG" : "JPEG",
      60,
      0,
      (uri) => {
        resolve(uri as File);
      },
      "file"
    );
  });
};

class FirebaseService {
  uploadImageAndThumbnail(
    path: string,
    file: File,
    keepTransparency?: boolean
  ): Promise<ImageAndThumbnailUploadResponse> {
    return new Promise((res, rej) => {
      const fileUuid = uuidv4();
      resizeFile(file, keepTransparency).then((thumbnailFile) => {
        $firebaseStorage
          .ref()
          .child(`${path}/${fileUuid}.jpg`)
          .put(file)
          .then((fullSizeImageUploadTask) => {
            fullSizeImageUploadTask.ref
              .getDownloadURL()
              .then((fullSizeImageDownloadUrl) => {
                $firebaseStorage
                  .ref()
                  .child(`${path}/thmb_${fileUuid}.jpg`)
                  .put(thumbnailFile as File)
                  .then((thumbnailImageUploadTask) => {
                    thumbnailImageUploadTask.ref
                      .getDownloadURL()
                      .then((thumbnailImageDownloadUrl) => {
                        res({
                          imagePath: `${path}/${fileUuid}.jpg`,
                          imageDownloadUrl: fullSizeImageDownloadUrl,
                          thumbnailPath: `${path}/thmb_${fileUuid}.jpg`,
                          thumbnailDownloadUrl: thumbnailImageDownloadUrl
                        });
                      })
                      .catch((err) => {
                        console.log(
                          "tumbnail-image upload error (dlurl): " + err
                        );
                        rej("tumbnail-image upload error (dlurl)");
                      });
                  })
                  .catch((err) => {
                    console.log("tumbnail-image upload error : " + err);
                    rej("tumbnail-image upload error");
                  });
              })
              .catch((err) => {
                console.log("full-image upload error (dlurl) : " + err);
                rej("full-image upload error (dlurl)");
              });
          })
          .catch((err) => {
            console.log("full-image upload error : " + err);
            rej("tumbnail-image upload error");
          });
      });
    });
  }

  listenToOneShow(showId: string, listenerCallback: Function) {
    const oneShowListener = $firestore
      .collection("shows")
      .doc(showId)
      .onSnapshot((doc) => {
        if (doc.exists) {
          listenerCallback(getHydratedShowFromFetch(doc));
        }
      });
    return oneShowListener;
  }
  // getOneShowById(showId: string): Promise<Show> {
  //   return new Promise((res, rej) => {
  //     $firestore
  //       .collection("shows")
  //       .doc(showId)
  //       .get()
  //       .then((doc) => {
  //         res(getHydratedShowFromFetch(doc));
  //       })
  //       .catch((err) => {
  //         rej(err);
  //       });
  //   });
  // }
  //NOTE ONLY FOR EXPORTS PURPOSE
  getAllShowPledgesByShowId(showId: string): Promise<
    {
      id: string;
      projectId: string;
      amount: number;
      email: string;
      isAdmin: boolean;
      date: Date;
      additionalInformations: Record<any, any>;
    }[]
  > {
    return new Promise((res, rej) => {
      $firestore
        .collection("shows")
        .doc(showId)
        .collection("pledges")
        .get()
        .then((snapshot) => {
          const pledges = [] as any[];
          snapshot.docs.forEach((pledge: Record<string, any>) => {
            pledges.push({
              id: pledge.id,
              projectId: pledge.data().projectId,
              amount: pledge.data().amount,
              email: pledge.data().email,
              isAdmin: pledge.data().isAdmin,
              date: new Date(pledge?.data()?.date?.seconds * 1000),
              additionalInformations: pledge.data().additionalInformations
            });
          });
          res(pledges);
        })
        .catch((err) => {
          rej(err);
        });
    });
  }
  //NOTE ONLY FOR EXPORTS PURPOSE
  getAllShowUsersByShowId(showId: string): Promise<
    {
      id: string;
      allowShare: boolean;
      askReceipt: boolean;
      onPlace: boolean;
      infos: Record<any, any>[];
    }[]
  > {
    return new Promise((res, rej) => {
      $firestore
        .collection("shows")
        .doc(showId)
        .collection("users")
        .get()
        .then((snapshot) => {
          const users = [] as any[];
          snapshot.docs.forEach((user: Record<string, any>) => {
            users.push({
              id: user.id,
              allowShare: user.data().allowShare,
              askReceipt: user.data().askReceipt,
              onPlace: user.data().onPlace,
              infos: user.data().infos
            });
          });
          res(users);
        })
        .catch((err) => {
          rej(err);
        });
    });
  }
  //NOTE ONLY FOR EXPORTS PURPOSE
  getAllShowChatMessages(showId: string): Promise<
    {
      email: string;
      username: string;
      content: string;
      date: Date;
      pledgeAmount: string;
    }[]
  > {
    return new Promise((res, rej) => {
      $firestore
        .collection("shows")
        .doc(showId)
        .collection("chat_messages_private")
        .get()
        .then((snapshot) => {
          const chatMessages = [] as {
            email: string;
            username: string;
            content: string;
            date: Date;
            pledgeAmount: string;
          }[];
          snapshot.docs.forEach((chatMessage: Record<string, any>) => {
            chatMessages.push({
              email: chatMessage.data().userInfos?.email,
              username: chatMessage.data().username,
              content: chatMessage.data().content,
              date: new Date(chatMessage?.data()?.date.seconds * 1000),
              pledgeAmount: (chatMessage.data().pledgeAmount / 100).toFixed(0)
            });
          });
          res(chatMessages);
        })
        .catch((err) => rej(err));
    });
  }

  listenToOneShowProjects(showId: string, listenerCallback: Function) {
    const oneShowProjectsListener = $firestore
      .collection("shows")
      .doc(showId)
      .collection("projects")
      .orderBy("position")
      .onSnapshot((snapshot) => {
        const projects = [] as ShowProject[];
        snapshot.docs.forEach((show: Record<string, any>) => {
          projects.push({
            id: show.id,
            position: show.data().position,
            name: show.data().name,
            goal: show.data().goal,
            logoUrl: show.data().logoUrl,
            totalPledgesAmount: show.data().totalPledgesAmount,
            backgroundColorLogo: show.data().backgroundColorLogo
          });
        });
        listenerCallback(projects);
      });
    return oneShowProjectsListener;
  }
  listenToAllShows(listenerCallback: Function, limit: number) {
    const allShowsListener = $firestore
      .collection("shows")
      .where("isArchived", "==", false)
      .orderBy("startDate", "desc")
      .limit(limit)
      .onSnapshot((snapshot) => {
        const shows = [] as Show[];
        snapshot.docs.forEach((show: Record<string, any>) => {
          shows.push(getHydratedShowFromFetch(show));
        });
        listenerCallback(shows);
      });
    return allShowsListener;
  }
  listenToShowPledges(showId: string, listenerCallback: Function) {
    const showPledgesListener = $firestore
      .collection("shows")
      .doc(showId)
      .collection("pledges")
      .onSnapshot((snapshot) => {
        const pledges = [] as ProjectPledge[];
        snapshot.docs.forEach((pledge: Record<string, any>) => {
          pledges.push({
            id: pledge.id,
            projectId: pledge.data().projectId,
            amount: pledge.data().amount,
            email: pledge.data().email,
            isAdmin: pledge.data().isAdmin,
            date: new Date(pledge?.data()?.date?.seconds * 1000)
          });
        });
        listenerCallback(pledges);
      });
    return showPledgesListener;
  }
  listenToShowChatMessages(showId: string, listenerCallback: Function) {
    const showPledgesListener = $firestore
      .collection("shows")
      .doc(showId)
      .collection("chat_messages")
      .onSnapshot((snapshot) => {
        const chatMessages = [] as ChatMessage[];
        snapshot.docs.forEach((chatMessage: Record<string, any>) => {
          chatMessages.push({
            id: chatMessage.id,
            username: chatMessage.data().username,
            content: chatMessage.data().content,
            date: new Date(chatMessage?.data()?.date.seconds * 1000),
            isAdmin: chatMessage.data().isAdmin,
            isDisabled: chatMessage.data().isDisabled,
            pledgeAmount: chatMessage.data().pledgeAmount
          });
        });
        listenerCallback(chatMessages);
      });
    return showPledgesListener;
  }
  createShow(showName: string): Promise<Show> {
    return new Promise((res, rej) => {
      const showId = uuidv4();
      const show = new Show(showId, showName, showId);
      $firestore
        .collection("shows")
        .doc(showId)
        .set(
          {
            name: show.name,
            urlSlug: show.urlSlug,
            isPublished: show.isPublished,
            isOnPlace: true,
            isOnRemote: true,
            place: "-",
            subtitle: "-",
            startDate: show.startDate,
            endDate: show.endDate,
            description: "-",
            endMessage: "Merci pour votre participation !",
            endButtonText: "Transformer mes promesses",
            endButtonUrl: "https://obole.eu/",
            comingButtonText: "S'informer / S'inscire",
            comingButtonUrl: "https://obole.eu/",
            pastButtonText: "Revoir l'événement",
            pastButtonUrl: "https://obole.eu/",
            coverUrl: show.coverUrl,
            logoUrl: show.logoUrl,
            isStreamingEnabled: show.isStreamingEnabled,
            youtubeStreamId: show.youtubeStreamId,
            hasPledgesPresets: true,
            firstPledgeAmount: 500000,
            secondPledgeAmount: 250000,
            thirdPledgeAmount: 100000,
            fourthPledgeAmount: 50000,
            pledgesCurrency: "EUR",
            hasFreePledgeAmount: true,
            hasTaxReceiptAvailable: show.hasTaxReceiptAvailable,
            hasCustomPledgeAmounts: show.hasCustomPledgeAmounts,
            isFirstnamePresetOn: show.isFirstnamePresetOn,
            isFirstnamePresetRequired: show.isFirstnamePresetRequired,
            isLastnamePresetOn: show.isLastnamePresetOn,
            isLastnamePresetRequired: show.isLastnamePresetRequired,
            isPhonePresetOn: show.isPhonePresetOn,
            isPhonePresetRequired: show.isPhonePresetRequired,
            isAddressFirstLinePresetOn: show.isAddressFirstLinePresetOn,
            isAddressFirstLinePresetRequired:
              show.isAddressFirstLinePresetRequired,
            isAddressSecondLinePresetOn: show.isAddressSecondLinePresetOn,
            isAddressSecondLinePresetRequired:
              show.isAddressSecondLinePresetRequired,
            isZipcodePresetOn: show.isZipcodePresetOn,
            isZipcodePresetRequired: show.isZipcodePresetRequired,
            isCityPresetOn: show.isCityPresetOn,
            isCityPresetRequired: show.isCityPresetRequired,
            isCountryPresetOn: show.isCountryPresetOn,
            isCountryPresetRequired: show.isCountryPresetRequired,
            customRegistrationsList: show.customRegistrationsList,
            currentProjectId: show.currentProjectId,
            isClosed: show.isClosed,
            totalPledgesAmount: show.totalPledgesAmount,
            hasNoProjects: true,
            customPledgeAmountsCount: show.customPledgeAmountsCount,
            averagePledgesAmount: show.averagePledgesAmount,
            allPledgesCount: show.allPledgesCount,
            totalUsersCount: show.totalUsersCount,
            forcedEndingAmount: show.forcedEndingAmount,
            globalShowGoal: show.globalShowGoal,
            isArchived: false,
            backgroundColor: "#13202C",
            fontColor: "#FFFFFF",
            colorPledge: "#848AAE",
            backgroundColorPledge: "#000000",
            backgroundColorPledgeBeyond: "#db693f"
          },
          { merge: true }
        )
        .then(() => {
          res(show);
        })
        .catch((e) => {
          console.log(e);
          rej(e);
        });
    });
  }
  duplicateShow(show: Show): Promise<Show> {
    return new Promise((res, rej) => {
      const showId = uuidv4();
      const originShowId = show.id
      show.id = showId
      $firestore
        .collection("shows")
        .doc(showId)
        .set({
            name: show.name,
            urlSlug: showId,
            isPublished: show.isPublished,
            isOnPlace: show.isOnPlace,
            isOnRemote: show.isOnRemote,
            place: show.place,
            subtitle: show.subtitle,
            startDate: show.startDate,
            endDate: show.endDate,
            description: show.description,
            endMessage: show.endMessage,
            endButtonText: show.endButtonText,
            endButtonUrl: show.endButtonUrl,
            comingButtonText: show.comingButtonText,
            comingButtonUrl: show.comingButtonUrl,
            pastButtonText: show.pastButtonText,
            pastButtonUrl: show.pastButtonUrl,
            coverUrl: show.coverUrl,
            logoUrl: show.logoUrl,
            isStreamingEnabled: show.isStreamingEnabled,
            youtubeStreamId: show.youtubeStreamId,
            hasPledgesPresets: show.hasPledgesPresets,
            firstPledgeAmount: show.firstPledgeAmount,
            secondPledgeAmount: show.secondPledgeAmount,
            thirdPledgeAmount: show.thirdPledgeAmount,
            fourthPledgeAmount: show.fourthPledgeAmount,
            pledgesCurrency: show.pledgesCurrency,
            hasFreePledgeAmount: show.hasFreePledgeAmount,
            hasTaxReceiptAvailable: show.hasTaxReceiptAvailable,
            hasCustomPledgeAmounts: show.hasCustomPledgeAmounts,
            isFirstnamePresetOn: show.isFirstnamePresetOn,
            isFirstnamePresetRequired: show.isFirstnamePresetRequired,
            isLastnamePresetOn: show.isLastnamePresetOn,
            isLastnamePresetRequired: show.isLastnamePresetRequired,
            isPhonePresetOn: show.isPhonePresetOn,
            isPhonePresetRequired: show.isPhonePresetRequired,
            isAddressFirstLinePresetOn: show.isAddressFirstLinePresetOn,
            isAddressFirstLinePresetRequired:
            show.isAddressFirstLinePresetRequired,
            isAddressSecondLinePresetOn: show.isAddressSecondLinePresetOn,
            isAddressSecondLinePresetRequired:
            show.isAddressSecondLinePresetRequired,
            isZipcodePresetOn: show.isZipcodePresetOn,
            isZipcodePresetRequired: show.isZipcodePresetRequired,
            isCityPresetOn: show.isCityPresetOn,
            isCityPresetRequired: show.isCityPresetRequired,
            isCountryPresetOn: show.isCountryPresetOn,
            isCountryPresetRequired: show.isCountryPresetRequired,
            customRegistrationsList: show.customRegistrationsList,
            currentProjectId: "",
            isClosed: show.isClosed,
            totalPledgesAmount: 0,
            hasNoProjects: show.hasNoProjects,
            customPledgeAmountsCount: show.customPledgeAmountsCount,
            averagePledgesAmount: 0,
            allPledgesCount: 0,
            totalUsersCount: 0,
            forcedEndingAmount: show.forcedEndingAmount,
            globalShowGoal: show.globalShowGoal,
            isArchived: false,
            backgroundColor: show.backgroundColor,
            fontColor: show.fontColor,
            colorPledge: show.colorPledge,
            backgroundColorPledge: show.backgroundColorPledge,
            backgroundColorPledgeBeyond: show.backgroundColorPledgeBeyond
          },
          { merge: true }
        )
        .then(() => {
          $firestore
            .collection("shows")
            .doc(originShowId)
            .collection("projects")
            .get()
            .then((snapshot) => {
              snapshot.docs.forEach((project: Record<string, any>) => {
                const projectId = uuidv4();
                $firestore
                  .collection("shows")
                  .doc(showId)
                  .collection("projects")
                  .doc(projectId)
                  .set(
                    {
                      position: project.data().position,
                      name: project.data().name,
                      goal: project.data().goal,
                      logoUrl: project.data().logoUrl,
                      totalPledgesAmount: 0,
                      backgroundColorLogo: project.data().backgroundColorLogo,
                      parentShowId: showId,
                      place: show.place
                    },
                    { merge: true }
                  );
              });
            });
          res(show);
        })
        .catch((e) => {
          console.log(e);
          rej(e);
        });
    });
  }

  updateShowInfos(
    showId: string,
    showInfos: {
      isPublished: boolean;
      isOnPlace: boolean;
      isOnRemote: boolean;
      name: string;
      place: string;
      subtitle: string;
      urlSlug: string;
      startDate: Date;
      endDate: Date;
      description: string;
      endMessage: string;
      endButtonText: string;
      endButtonUrl: string;
      comingButtonText: string;
      comingButtonUrl: string;
      pastButtonText: string;
      pastButtonUrl: string;
      forcedEndingAmount: string;
      globalShowGoal: string;
      isLnbc: boolean;
      backgroundColor: string;
      fontColor: string;
      colorPledge: string;
      backgroundColorPledge: string;
      backgroundColorPledgeBeyond: string;
    }
  ): Promise<boolean> {
    return new Promise((res, rej) => {
      $firestore
        .collection("shows")
        .doc(showId)
        .set(
          {
            isPublished: showInfos.isPublished,
            isOnPlace: showInfos.isOnPlace,
            isOnRemote: showInfos.isOnRemote,
            name: showInfos.name,
            place: showInfos.place,
            subtitle: showInfos.subtitle,
            urlSlug: showInfos.urlSlug,
            startDate: showInfos.startDate,
            endDate: showInfos.endDate,
            description: showInfos.description,
            endMessage: showInfos.endMessage,
            endButtonText: showInfos.endButtonText,
            endButtonUrl: showInfos.endButtonUrl,
            comingButtonText: showInfos.comingButtonText,
            comingButtonUrl: showInfos.comingButtonUrl,
            pastButtonText: showInfos.pastButtonText,
            pastButtonUrl: showInfos.pastButtonUrl,
            forcedEndingAmount: +showInfos.forcedEndingAmount * 100,
            globalShowGoal: +showInfos.globalShowGoal * 100,
            isLnbc: showInfos.isLnbc,
            backgroundColor: showInfos.backgroundColor,
            fontColor: showInfos.fontColor,
            colorPledge: showInfos.colorPledge,
            backgroundColorPledge: showInfos.backgroundColorPledge,
            backgroundColorPledgeBeyond: showInfos.backgroundColorPledgeBeyond
          },
          { merge: true }
        )
        .then(() => {
          res(true);
        })
        .catch((e) => {
          console.log(e);
          rej(e);
        });
    });
  }


  async updateShowMedias(
    showId: string,
    existingCoverUrl: string,
    existingLogoUrl: string,
    pendingCoverFile: File,
    pendingLogoFile: File,
    isStreamingEnabled: boolean,
    youtubeStreamId: string
  ): Promise<boolean> {
    return new Promise(async (res, rej) => {
      let coverUrl = existingCoverUrl;
      let logoUrl = existingLogoUrl;
      if (pendingCoverFile?.size !== 0) {
        const coverUploadResponse = await this.uploadImageAndThumbnail(
          `medias`,
          pendingCoverFile
        );
        coverUrl = coverUploadResponse.thumbnailDownloadUrl;
      }
      if (pendingLogoFile?.size !== 0) {
        const logoUploadResponse = await this.uploadImageAndThumbnail(
          `medias`,
          pendingLogoFile,
          true
        );
        logoUrl = logoUploadResponse.thumbnailDownloadUrl;
      }
      $firestore
        .collection("shows")
        .doc(showId)
        .set(
          {
            coverUrl,
            logoUrl,
            isStreamingEnabled,
            youtubeStreamId
          },
          { merge: true }
        )
        .then(() => {
          res(true);
        })
        .catch((e) => {
          console.log("error update show media:", e);
          rej(e);
        });
    });
  }
  updateShowPledgesConfig(
    showId: string,
    hasPledgesPresets: boolean,
    firstPledgeAmount: string,
    secondPledgeAmount: string,
    thirdPledgeAmount: string,
    fourthPledgeAmount: string,
    pledgesCurrency: string,
    hasFreePledgeAmount: boolean,
    hasTaxReceiptAvailable: boolean,
    hasCustomPledgeAmounts: boolean,
    customPledgeAmountsList: CustomUserPledgeAmounts[],
    customPledgeAmountsCount: number
  ): Promise<boolean> {
    return new Promise(async (res, rej) => {
      $firestore
        .collection("shows")
        .doc(showId)
        .set(
          {
            hasPledgesPresets,
            firstPledgeAmount: +firstPledgeAmount * 100,
            secondPledgeAmount: +secondPledgeAmount * 100,
            thirdPledgeAmount: +thirdPledgeAmount * 100,
            fourthPledgeAmount: +fourthPledgeAmount * 100,
            pledgesCurrency,
            hasFreePledgeAmount,
            hasTaxReceiptAvailable,
            hasCustomPledgeAmounts,
            customPledgeAmountsCount: customPledgeAmountsCount
          },
          { merge: true }
        )
        .then(() => {
          if (!customPledgeAmountsList.length) {
            res(true);
          } else {
            customPledgeAmountsList
              .map((userPledgeAmounts) => {
                return () => {
                  $firestore
                    .collection("shows")
                    .doc(showId)
                    .collection("custom_user_amounts")
                    .doc(userPledgeAmounts.email)
                    .set(
                      {
                        firstAmount: +userPledgeAmounts.firstAmount * 100,
                        secondAmount: +userPledgeAmounts.secondAmount * 100,
                        thirdAmount: +userPledgeAmounts.thirdAmount * 100,
                        fourthAmount: +userPledgeAmounts.fourthAmount * 100,
                        freeAmount: userPledgeAmounts.freeAmount
                      },
                      { merge: true }
                    );
                };
              })
              .reduce((prev, task) => {
                return prev.then(task).catch((err) => {
                  console.log(err);
                  rej(err);
                });
              }, Promise.resolve());
            res(true);
          }
        })
        .catch((e) => {
          console.log(e);
          rej(e);
        });
    });
  }
  deleteShowProject(
    showId: string,
    projectId: string,
    hasNoMoreProjects: boolean
  ): Promise<boolean> {
    return new Promise((res, rej) => {
      $firestore
        .collection("shows")
        .doc(showId)
        .collection("projects")
        .doc(projectId)
        .delete()
        .then((response) => {
          $firestore
            .collection("shows")
            .doc(showId)
            .set(
              {
                hasNoProjects: hasNoMoreProjects,
                currentProjectId: ""
              },
              { merge: true }
            )
            .then(() => {
              res(true);
            });
        })
        .catch((err) => {
          rej(false);
        });
    });
  }
  updateProjectsConfig(
    showId: string,
    projectsList: ShowProject[]
  ): Promise<boolean> {
    return new Promise((res, rej) => {
      projectsList
        .map((project) => {
          return () => {
            $firestore
              .collection("shows")
              .doc(showId)
              .collection("projects")
              .doc(project.id)
              .set(
                {
                  position: project.position
                },
                { merge: true }
              );
          };
        })
        .reduce((prev, task) => {
          return prev.then(task).catch((err) => {
            console.log(err);
            rej(err);
          });
        }, Promise.resolve());
      res(true);
    });
  }
  async writeShowProject(
    showId: string,
    projectId: string,
    pendingProjectName: string,
    pendingProjectPosition: number,
    pendingProjectGoal: string,
    pendingProjectPendingLogoFile: File,
    pendingProjectLogoUrl: string,
    pendingTotalPledgesAmount: number,
    backgroundColorLogo: string
  ): Promise<boolean> {
    return new Promise(async (res, rej) => {
      let logoUrl = pendingProjectLogoUrl;
      if (pendingProjectPendingLogoFile?.size !== 0) {
        const logoUploadResponse = await this.uploadImageAndThumbnail(
          `medias`,
          pendingProjectPendingLogoFile,
          true
        );
        logoUrl = logoUploadResponse.thumbnailDownloadUrl;
      }
      $firestore
        .collection("shows")
        .doc(showId)
        .collection("projects")
        .doc(projectId)
        .set(
          {
            name: pendingProjectName,
            position: pendingProjectPosition,
            goal: +pendingProjectGoal * 100,
            totalPledgesAmount: pendingTotalPledgesAmount,
            logoUrl,
            backgroundColorLogo
          },
          { merge: true }
        )
        .then(() => {
          $firestore
            .collection("shows")
            .doc(showId)
            .set(
              {
                hasNoProjects: false
              },
              { merge: true }
            )
            .then(() => {
              res(true);
            })
            .catch((e) => {
              rej(e);
            });
        })
        .catch((e) => {
          console.log(e);
          rej(e);
        });
    });
  }
  updateShowRegistrations(
    showId: string,
    isFirstnamePresetOn: boolean,
    isFirstnamePresetRequired: boolean,
    isLastnamePresetOn: boolean,
    isLastnamePresetRequired: boolean,
    isPhonePresetOn: boolean,
    isPhonePresetRequired: boolean,
    isAddressFirstLinePresetOn: boolean,
    isAddressFirstLinePresetRequired: boolean,
    isAddressSecondLinePresetOn: boolean,
    isAddressSecondLinePresetRequired: boolean,
    isZipcodePresetOn: boolean,
    isZipcodePresetRequired: boolean,
    isCityPresetOn: boolean,
    isCityPresetRequired: boolean,
    isCountryPresetOn: boolean,
    isCountryPresetRequired: boolean,
    customRegistrationsList: CustomRegistration[],
    isPseudoPresetOn: boolean,
    isPseudoPresetRequired: boolean,
  ): Promise<boolean> {
    return new Promise((res, rej) => {
      $firestore
        .collection("shows")
        .doc(showId)
        .set(
          {
            isFirstnamePresetOn,
            isFirstnamePresetRequired,
            isLastnamePresetOn,
            isLastnamePresetRequired,
            isPhonePresetOn,
            isPhonePresetRequired,
            isAddressFirstLinePresetOn,
            isAddressFirstLinePresetRequired,
            isAddressSecondLinePresetOn,
            isAddressSecondLinePresetRequired,
            isZipcodePresetOn,
            isZipcodePresetRequired,
            isCityPresetOn,
            isCityPresetRequired,
            isCountryPresetOn,
            isCountryPresetRequired,
            customRegistrationsList,
            isPseudoPresetOn,
            isPseudoPresetRequired,
          },
          { merge: true }
        )
        .then((response) => {
          res(true);
        })
        .catch((err) => {
          console.log(err);
          rej(err);
        });
    });
  }
  changeCurrentProjectId(showId: string, projectId: string) {
    $firestore
      .collection("shows")
      .doc(showId)
      .set(
        {
          currentProjectId: projectId
        },
        { merge: true }
      )
      .then((response) => {})
      .catch((err) => {
        console.log(err);
      });
  }
  createAdminPledge(
    showId: string,
    projectId: string,
    amount: number,
    multiplexId?: string,
    dashboardId?: string
  ): Promise<boolean> {
    return new Promise((res, rej) => {
      const newPledgeId = uuidv4();
      $firestore
        .collection("shows")
        .doc(showId)
        .collection("pledges")
        .doc(newPledgeId)
        .set(
          {
            projectId: projectId,
            email: "",
            amount: amount,
            date: firebase.firestore.FieldValue.serverTimestamp(),
            isAdmin: true
          },
          { merge: true }
        )
        .then((response) => {
          res(true);
        })
        .catch((err) => {
          console.log(err);
          rej(err);
        });
    });
  }
  deletePledge(showId: string, pledgeId: string): Promise<boolean> {
    return new Promise((res, rej) => {
      $firestore
        .collection("shows")
        .doc(showId)
        .collection("pledges")
        .doc(pledgeId)
        .delete()
        .then((response) => {
          res(true);
        })
        .catch((err) => {
          rej(false);
        });
    });
  }
  createAdminChatMessage(
    showId: string,
    username: string,
    content: string,
    date: Date,
    isAdmin: boolean,
    isDisabled: boolean
  ): Promise<boolean> {
    return new Promise((res, rej) => {
      const newChatMessageId = uuidv4();
      $firestore
        .collection("shows")
        .doc(showId)
        .collection("chat_messages")
        .doc(newChatMessageId)
        .set(
          {
            username: username,
            content: content,
            date: date,
            isAdmin: isAdmin,
            isDisabled: isDisabled,
            pledgeAmount: 0
          },
          { merge: true }
        )
        .then((response) => {
          res(true);
        })
        .catch((err) => {
          console.log(err);
          rej(err);
        });
    });
  }
  changeChatMessageStatus(
    showId: string,
    chatMessageId: string,
    isDisabled: boolean
  ): Promise<boolean> {
    return new Promise((res, rej) => {
      $firestore
        .collection("shows")
        .doc(showId)
        .collection("chat_messages")
        .doc(chatMessageId)
        .set(
          {
            isDisabled: isDisabled
          },
          { merge: true }
        )
        .then((response) => {
          res(true);
        })
        .catch((err) => {
          console.log(err);
          rej(err);
        });
    });
  }
  changeShowClosingStatus(showId: string, isClosed: boolean): Promise<boolean> {
    return new Promise((res, rej) => {
      $firestore
        .collection("shows")
        .doc(showId)
        .set(
          {
            isClosed: isClosed
          },
          { merge: true }
        )
        .then((response) => {
          res(true);
        })
        .catch((err) => {
          console.log(err);
          rej(err);
        });
    });
  }
  deleteShow(showId: string): Promise<boolean> {
    return new Promise((res, rej) => {
      $firestore
        .collection("shows")
        .doc(showId)
        .set(
          {
            isClosed: true,
            isPublished: false,
            isArchived: true,
          },
          { merge: true }
        )
        .then((response) => {
          res(true);
        })
        .catch((err) => {
          rej(false);
        });
    });
  }

  listenToAllMultiplexes(listenerCallback: Function, limit: number) {
    const allMultiplexesListener = $firestore
      .collection("multiplexes")
      .where("isArchived", "==", false)
      .onSnapshot((snapshot) => {
        const multiplexes: any = [] as Multiplex[];
        snapshot.docs.forEach((multiplex: Record<string, any>) => {
          multiplexes.push(getHydratedMultiplexFromFetch(multiplex));
        });
        listenerCallback(multiplexes);
      });
    return allMultiplexesListener;
  }
  listenToOneMultiplex(multiplexId: string, listenerCallback: Function) {
    const oneMultiplexListener = $firestore
      .collection("multiplexes")
      .doc(multiplexId)
      .onSnapshot((doc) => {
        if (doc.exists) {
          listenerCallback(getHydratedMultiplexFromFetch(doc));
        }
      });
    return oneMultiplexListener;
  }
  createMultiplex(multiplexName: string): Promise<Multiplex> {
    return new Promise((res, rej) => {
      const multiplexId = uuidv4();
      const multiplex = new Multiplex(multiplexId, multiplexName);
      $firestore
        .collection("multiplexes")
        .doc(multiplexId)
        .set(
          {
            name: multiplex.name,
            subtitle: "",
            startDate: multiplex.startDate,
            logoUrl: "",
            participatingShows: [],
            pledgesCurrency: "EUR",
            totalPledgesAmount: 0,
            averagePledgesAmount: 0,
            isArchived: false,
            displayProgressBar: false
          },
          { merge: true }
        )
        .then(() => {
          res(multiplex);
        })
        .catch((e) => {
          console.log(e);
          rej(e);
        });
    });
  }

  async updateMultiplexInfos(
    multiplexId: string,
    name: string,
    subtitle: string,
    startDate: Date,
    existingLogoUrl: string,
    pendingLogoFile: File,
    participatingShows: string[],
    pledgesCurrency: string,
    backgroundColorDark: string,
    backgroundColorLight: string,
    fontColor: string,
    fontColorHighlight: string,
    globalMultiplexGoal: number
  ): Promise<boolean> {
    return new Promise(async (res, rej) => {
      let logoUrl = existingLogoUrl;
      if (pendingLogoFile?.size !== 0) {
        const logoUploadResponse = await this.uploadImageAndThumbnail(
          `medias`,
          pendingLogoFile,
          true
        );
        logoUrl = logoUploadResponse.thumbnailDownloadUrl;
      }
      $firestore
        .collection("multiplexes")
        .doc(multiplexId)
        .set(
          {
            name: name,
            subtitle: subtitle,
            startDate: startDate,
            logoUrl: logoUrl,
            participatingShows: participatingShows,
            pledgesCurrency: pledgesCurrency || "EUR",
            backgroundColorDark: backgroundColorDark,
            backgroundColorLight: backgroundColorLight,
            fontColor: fontColor,
            fontColorHighlight: fontColorHighlight,
            globalMultiplexGoal: globalMultiplexGoal
          },
          { merge: true }
        )
        .then(() => {
          res(true);
        })
        .catch((e) => {
          console.log(e);
          rej(e);
        });
    });
  }

  deleteMultiplex(multiplexId: string): Promise<boolean> {
    return new Promise((res, rej) => {
      $firestore
        .collection("multiplexes")
        .doc(multiplexId)
        .set(
          {
            isArchived: true
          },
          { merge: true }
        )
        .then((response) => {
          res(true);
        })
        .catch((err) => {
          rej(false);
        });
    });
  }

  listenToOneMultiplexDashboards(multiplexId: string, listenerCallback: Function) {
    const oneMultiplexDashboardsListener = $firestore
      .collection("multiplexes")
      .doc(multiplexId)
      .collection("dashboards")
      .orderBy("position")
      .onSnapshot((snapshot) => {
        const dashboards = [] as MultiplexDashboard[];
        snapshot.docs.forEach((dashboard: Record<string, any>) => {
          dashboards.push({
            id: dashboard.id,
            name: dashboard.data().name,
            goal: dashboard.data().goal,
            logoUrl: dashboard.data().logoUrl,
            totalPledgesAmount: dashboard.data().totalPledgesAmount,
            selectedProjects: dashboard.data().selectedProjects,
            position: dashboard.data().position
          });
        });
        listenerCallback(dashboards);
      });
    return oneMultiplexDashboardsListener;
  }

  async writeMultiplexDashboard(
    multiplexId: string,
    dashboardId: string,
    pendingDashboardName: string,
    pendingDashboardPendingLogoFile: File,
    pendingDashboardLogoUrl: string,
    pendingDashboardProjects: DashboardProject[],
    pendingDashboardGoal: number,
    pendingDashboardPosition: number,
    editMode: boolean
  ): Promise<boolean> {
    return new Promise(async(res, rej) => {
      let logoUrl = pendingDashboardLogoUrl;
      if (pendingDashboardPendingLogoFile?.size !== 0) {
        const logoUploadResponse = await this.uploadImageAndThumbnail(
          `medias`,
          pendingDashboardPendingLogoFile,
          true
        );
        logoUrl = logoUploadResponse.thumbnailDownloadUrl;
      }
      const setContent = editMode ?
       {
        name: pendingDashboardName,
        logoUrl,
        goal: pendingDashboardGoal,
        selectedProjects: pendingDashboardProjects,
      } : {
        name: pendingDashboardName,
        logoUrl,
        goal: pendingDashboardGoal,
        totalPledgesAmount: 0,
        selectedProjects: pendingDashboardProjects,
        position: pendingDashboardPosition,
      }
      $firestore
        .collection("multiplexes")
        .doc(multiplexId)
        .collection("dashboards")
        .doc(dashboardId)
        .set(
          setContent,
          { merge: true }
        )
        .then((response) => {
          if (!editMode) {
            pendingDashboardProjects.forEach(project => {
              $firestore
                .collection("multiplexes")
                .doc(multiplexId)
                .collection("dashboards")
                .doc(dashboardId)
                .collection("localCounts")
                .doc(project.parentShowId)
                .set({
                  place: project.place,
                  totalPledgesAmount: 0,
                  selected: false
                }, {merge: true})
                .catch((e) => {
                  console.log(e);
                });
            })
          }
          res(true)
        })
        .catch((e) => {
          console.log(e);
          rej(e);
        });
    });
  }

  deleteMultiplexDashboard(
    multiplexId: string,
    dashboardId: string,
  ): Promise<boolean> {
    return new Promise((res, rej) => {
      $firestore
        .collection("multiplexes")
        .doc(multiplexId)
        .collection("dashboards")
        .doc(dashboardId)
        .delete()
        .then((response) => {
          res(true)
        })
        .catch((err) => {
          rej(false);
        });
    });
  }

  changeCurrentDashboardId(multiplexId: string, dashboardId: string) {
    $firestore
      .collection("multiplexes")
      .doc(multiplexId)
      .set({
        currentDashboardId: dashboardId
      }, { merge: true })
      .then((response) => {})
      .catch((err) => {
        console.log(err);
      });
  }

  bindDashboardtoProject(showId: string, projectId: string, dashboardId: string): Promise<boolean> {
    return new Promise((res, rej) => {
      $firestore
        .collection("shows")
        .doc(showId)
        .collection("projects")
        .doc(projectId)
        .update(
          {dashboardId}
        )
        .then(() => {
          res(true);
        })
        .catch((e) => {
          console.log(e);
          rej(e);
        });
    });
  }

  unbindDashboardtoProject(showId: string, projectId: string): Promise<boolean> {
    return new Promise((res, rej) => {
      $firestore
        .collection("shows")
        .doc(showId)
        .collection("projects")
        .doc(projectId)
        .update(
          {dashboardId: ""}
        )
        .then(() => {
          res(true);
        })
        .catch((e) => {
          console.log(e);
          rej(e);
        });
    });
  }

  listenToSelectedDashboardLocalities(
    multiplexId: string,
    dashboardId: string,
    callback: Function
  ) {
    const dashLocalities = $firestore
      .collection("multiplexes")
      .doc(multiplexId)
      .collection("dashboards")
      .doc(dashboardId)
      .collection("localCounts")
      .orderBy("place", "asc")
      .onSnapshot((snapshot) => {
        const snapshotList = [] as DashboardLocalCount[]
        snapshot.docs.forEach((localCount: Record<string, any>) => {
          snapshotList.push({
            id: localCount.id,
            place: localCount.data().place,
            totalPledgesAmount: localCount.data().totalPledgesAmount,
            selected: localCount.data().selected
          });
        });
        callback(snapshotList);
      });
    return dashLocalities
  }

  toggleSelectedDashboardLocalities(
    multiplexId: string,
    dashboardId: string,
    localityId: string,
    selectionSize: number,
    callback: Function
  ) {
    $firestore
      .collection("multiplexes")
      .doc(multiplexId)
      .collection("dashboards")
      .doc(dashboardId)
      .collection("localCounts")
      .doc(localityId)
      .get()
      .then((doc) => {
        // @ts-ignore
        if (doc.exists && doc.data().selected === false && selectionSize === 3) {
          callback()
        } else {
          // @ts-ignore
          return doc.ref.update({selected: !doc.data().selected})
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  getMultiplexProjectsList(
    showIds: string[],
    callback: Function
  ) {
    const multiplexProjectsList = [] as ShowProject[]
    if (showIds) {
      showIds.forEach(showId => {
        let place: string
        $firestore
          .collection("shows")
          .doc(showId)
          .get()
          .then((snapshot) => {
            // @ts-ignore
            place = snapshot.data() ? snapshot.data().place : ""

            $firestore
              .collection("shows")
              .doc(showId)
              .collection("projects")
              .get()
              .then((snapshot) => {
                snapshot.docs.forEach((project) => {
                  multiplexProjectsList.push({
                    id: project.id,
                    position: project.data().position,
                    name: project.data().name,
                    goal: project.data().goal,
                    logoUrl: project.data().logoUrl,
                    totalPledgesAmount: project.data().totalPledgesAmount,
                    backgroundColorLogo: project.data().backgroundColorLogo,
                    parentShowId: showId,
                    place: place
                  })
                })
              })
          })
      })
    }
    Promise.all(multiplexProjectsList)
      .then(callback(multiplexProjectsList))
      .catch((err) => {
        console.log(err);
      });
  }

  updateDashboardsConfig(
    multiplexId: string,
    dashboardsList: MultiplexDashboard[]
  ): Promise<boolean> {
    return new Promise((res, rej) => {
      dashboardsList
        .map((dashboard) => {
          return () => {
            $firestore
              .collection("multiplexes")
              .doc(multiplexId)
              .collection("dashboards")
              .doc(dashboard.id)
              .set(
                {
                  position: dashboard.position
                },
                { merge: true }
              );
          };
        })
        .reduce((prev, task) => {
          return prev.then(task).catch((err) => {
            console.log(err);
            rej(err);
          });
        }, Promise.resolve());
      res(true);
    });
  }

  getAllShowsBasicInfos(
    callback: Function
  ) {
    $firestore
      .collection("shows")
      .where("isArchived", "==", false)
      .orderBy("startDate", "desc")
      .get()
      .then((snapshot) => {
        const shows = [] as {
          id: string
          name: string
          place: string
        }[]
        snapshot.docs.forEach((show: Record<string, any>) => {
          shows.push({
            id: show.id,
            name: show.data().name,
            place: show.data().place
          });
        });
        callback(shows);
      });
  }

  toggleMultiplexDisplay(
    multiplexId: string,
    displayProgressBarValue: boolean
  ){
    $firestore
      .collection("multiplexes")
      .doc(multiplexId)
      .set(
        {displayProgressBar: !displayProgressBarValue}, { merge: true }
      )
      .catch((e) => {
        console.log(e);
      });
  }

}
export { $firestore, $firebaseAuth, $firebaseStorage };
export default FirebaseService;
